const OSS = require('ali-oss');

import { getOSSAuth } from '@/api/upload.js';
import { resStatusEnum } from '@/enumerators/http.js';
const { SUCCESS } = resStatusEnum;

export function createOSSClient() {
  return new Promise((resolve, reject) => {
    getOSSAuth().then(res => {
      if (res.code === SUCCESS) {
        const {
          region,
          accessKeyId,
          accessKeySecret,
          bucket,
          securityToken: stsToken,
          folder
        } = res.data;
        const OSSClient = new OSS({
          region,
          accessKeyId,
          accessKeySecret,
          bucket,
          stsToken
        });
        resolve({ OSSClient, folder });
      } else {
        reject(new Error('Get OSS Auth Error'));
      }
    });
  });
}

export function putFile({ OSSClient, file, filePath }) {
  return OSSClient.put(filePath, file);
}