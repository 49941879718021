const fileTypeEnum = {
  FILE: 1, // 其他
  VIDEO: 2 // 音频、视频
};

export function getFileTypeFromExt(extWithDot) {
  switch (extWithDot) {
    case '.mp4':
    case '.3gp':
    case '.wmv':
      return fileTypeEnum.VIDEO;
    case '.jpg':
    case '.jpeg':
    case '.png':
    case '.gif':
      return fileTypeEnum.FILE;
  }
}

export default fileTypeEnum;